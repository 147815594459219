<template>
  <div class="search-container">
    <div class="scroll-container">
      <div class="content-wrapper">
        
            <ul  class="left">
                <li :class="{active: activeTab === 'a'}" @click="handleToggleTab('a')">产品信息</li>
                <li :class="{active: activeTab === 'f'}" @click="handleToggleTab('f')">产品动态</li>
                <li :class="{active: activeTab === 'b'}" @click="handleToggleTab('b')">活动日历</li>
                <li :class="{active: activeTab === 'c'}" @click="handleToggleTab('c')">临床视角</li>
                <li :class="{active: activeTab === 'd'}" @click="handleToggleTab('d')">动态资讯</li>
                <li :class="{active: activeTab === 'e'}" @click="handleToggleTab('e')">会议大厅</li>
            </ul>
        
        
        <div class="right">
          <div class="title">共有关于“{{ searchName }}”的{{ searchCount }}条搜索结果</div>
          <div class="date" v-if="activeTab != 'a'">日期选择
            <el-date-picker
                v-model="date"
                @change="chooseDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
          <!-- 会议--->
          <div v-if="activeTab == 'e'" class="list-box-wrap">
            <ul class="search-list" v-if="list.length" @scroll="scroll">
                <li class="hyItem" v-for="(item,index) in list" :key="index"  @click="getLivePath(item)">
                    <img :src="item.coverImg || '/system/file/merck/1350049990842855425'" class="img" alt="">
                    <div class="bottom">
                        <p class="ellipsis t">{{item.title}}</p>
                        <p class="userInfo">
                        <span class="user" v-if="item.lecturerList.length">{{ item.avatar.join(',') }}</span>
                        <span class="date">{{ item.liveTime }}</span>
                        </p>
                         <p class="userInfo">
                          <span  class="author">角色：{{item.attendType | attendTypeFilter}}</span>
                          <span  class="author">会议形式：{{item.memberSigninType | memberTypeFilter}}</span>
                        </p>
                    </div>
                </li>
            </ul>
            <div class="empty-box" v-else>
                暂无内容
            </div>
          </div>
          <!--其他--->
          <div v-else class="list-box-wrap">
            <ul class="list-wrapper" v-if="dataList.length">
              <li v-for="(item,index) in dataList" :key="index" @click="goDetail(item.id,item.realmId,item.productIds,item)">
                <img v-if="activeTab != 'd'" :src="activeTab == 'a' || activeTab == 'b' ? item.coverImg ||  defaultImg: item.titleImg || defaultImg" class="img" alt="">
                <div class="ct">
                  <h3 class="ct-title" :title="item.name">{{activeTab == 'a' ? item.name :item.title}}</h3>
                  <p class="des" v-html="highLightSearch(activeTab == 'a' ? item.functions : item.info)"></p>
                  <div class="tag" v-if="item.tag ||item.realmName "><span class="t">{{activeTab == 'b' ? item.tag : item.realmName}}</span><span class="t" v-if="item.productName">{{item.productName}}</span></div>
                  <p class="des bottom0 time" v-if="item.releaseTime">{{item.releaseTime}}</p>
                  <p class="des bottom0" v-if="activeTab == 'b'">活动时间：{{item.activityTime}}</p>
                  <p class="des bottom0" v-if="activeTab == 'b'">会议讲者：{{item.lecturer}}</p>
                </div>
              </li>
            </ul>
            <div  class="empty-box" v-else>
                暂无内容
            </div>
          </div>
        </div>
        <div class="input-wrapper" :class="{active: isFocus}">
          <input type="text" class="ipt" placeholder="请输入..."  @keyup.enter="inputSearch" @focus="isFocus = true" @blur="isFocus = false" v-model.trim="searchName" />
        </div>
      </div>
    </div>
     <!-- 会议链接弹框 -->
    <!-- <MeetModel v-if="onlineDialogVisible" :meetingInfo="meetingInfo" :visible.sync='onlineDialogVisible' @close='onlineDialogVisible = false' /> -->
  </div>
</template>

<script>
import indexApi from '@/api/index'
// import MeetModel from '@/components/site/model'
export default {
  // components: {MeetModel},
  name: "searchPage",
  data() {
    const searchName = this.$route.query.search || '无';
    return {
      activeTab: 'a',
      searchName,
      searchCount: '',
      data: [],
      isFocus: false,
      list: [
        
      ],
     defaultImg: require('../../../public/images/h-3.png'),
      dataList: [],
      pageSize: '', 
      page: '1',
      date:[],//日期
      hasMore:false,
      loading:false,
      onlineDialogVisible:false,
      meetingInfo:{}
    }
  },
  created() {
    let queryStr = this.$route.query.type;
  
    if(queryStr) {
      this.activeTab = queryStr;
    } else{
      this.activeTab = 'a'
    }

    this.searchName = this.$route.query.keywords;

    this.handleToggleTab(this.activeTab)
    
  },
  mounted() {
    // this.getData();
  },
  methods: {
    //高亮显示关键字
    highLightSearch(name) {
        console.log('name===========',this.searchName,name)
      if (this.searchName && name) {
        return name.replace(
          new RegExp(this.searchName, "g"),
          '<span style="color: #fff; background: #21b8ce;">' + this.searchName + "</span>"
        );
      } else {
        return name;
      }
    },
    handleToggleTab(n) {
      if(this.activeTab  != n){
          this.date = [];
          this.list = [];
          this.dataList = [];
          this.page = '1';
      }
      this.activeTab = n;
      
      if(n == 'a') {
        this.getProductSearchList()
      } else if(n == 'b') {
        this.getActivityList()
      } else if(n == 'c') {
        this.getPerspectiveList();
      } else if(n == 'd') {
        this.getDynamicNews()
      } else if(n == 'e') {
        this.getPlaybackList()
      }else if(n == 'f') {
        this.getDynamicList() //产品动态
      }
    },

    // 产品搜索列表
    getProductSearchList() {
      let params = {
        "condition": {
          "keywords": this.searchName,//关键词
        },
        "page": this.page,
        "size": ''
      };
      indexApi.getProductSearchList(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data && res.data.list || [];
          this.dataList = data;

          this.dataList.map(item=>{
            item.avatar = [];
            (item && item.lecturerList || []).map(every=>{
              item.avatar.push(every.name);
            })
          })
          this.searchCount = data.length;
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },


    // 活动日历搜索列表
    getActivityList() {
      let params = {
        "condition": {
          "keywords": this.searchName,//关键词
          "startTime":this.date[0],
          "endTime":this.date[1],
        },
        "page": this.page,
        "size": ''
      };
      indexApi.getActivityList(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data && res.data.list || [];
          this.dataList = data;

          this.dataList.map(item=>{
            item.avatar = [];
            (item && item.lecturerList || []).map(every=>{
              item.avatar.push(every.name);
            })
          })
          this.searchCount = data.length;
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },

    // 临床视角搜索列表
    getPerspectiveList() {
      let params = {
        "condition": {
          "keywords": this.searchName,//关键词
          "startTime":this.date[0],
          "endTime":this.date[1],
        },
        "page": this.page,
        "size": ''
      };
      indexApi.getPerspectiveList(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data && res.data.list || [];
          this.dataList = data;

          this.dataList.map(item=>{
            item.avatar = [];
            (item && item.lecturerList || []).map(every=>{
              item.avatar.push(every.name);
            })
          })
          this.searchCount = data.length;
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },

    // 动态咨询搜索列表
    getDynamicNews() {
      let params = {
        "condition": {
          "keywords": this.searchName,//关键词
          "startTime":this.date[0],
          "endTime":this.date[1]
        },
        "page": this.page,
        "size": ''
      };
      indexApi.getDynamicNews(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data && res.data.list || [];
          this.dataList = data;

          this.dataList.map(item=>{
            item.avatar = [];
            (item && item.lecturerList || []).map(every=>{
              item.avatar.push(every.name);
            })
          })
          this.searchCount = data.length;
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },

    //产品动态
    getDynamicList() {
      let params = {
        "condition": {
         "keywords": this.searchName,//关键词
          "startTime":this.date[0],
          "endTime":this.date[1]
        },
        "page": this.page,
        "size": ''
      };
      indexApi.getProductDynamicList(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data && res.data.list || [];
          this.dataList = data;   
          console.log('this.list',this.list)      
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },
    // 获取会议回放数据
    getPlaybackList() {
      if( this.loading ){
        return;
      }
      let params = {
        "condition": {
          "keywords": this.searchName,//关键词
          "status": "", //会议回放传-1
          "startTime":this.date[0],
          "endTime":this.date[1]
        },
       
        "page": this.page,
        "size": 9
      };
      
      this.loading = true;
      indexApi.getPlaybackList(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data && res.data.list || [];
          if( this.list.length <res.data.total){
            this.list = this.list.concat(data);
            this.list.map(item=>{
              item.avatar = [];
              (item && item.lecturerList || []).map(every=>{
                item.avatar.push(every.name);
              })
            })
          }
          
          if(res.data.pageNums > this.page){
                this.page ++;
                this.hasMore = true;
                console.log('还有更多================')
          }else{
               this.hasMore = false;
          }
          this.loading = false;
          this.searchCount = res.data.total;
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },
    
    //跳转到详情
    goDetail(id,realmId,productIds,item){
        let url = '';
        if(this.activeTab == 'a') { //产品信息
            this.$router.push(`product?id=${id}`)
        } else if(this.activeTab == 'b') { //活动日历
            this.$router.push(`activity-calendar-detail?id=${id}`)
        } else if(this.activeTab == 'c') { //临床视角
            url = '/medical-records-detail/' + id
            window.location.href = url;
        } else if(this.activeTab == 'd') { //动态资讯
            url = '/news-details/' + id
            window.location.href = url;
        } else if(this.activeTab == 'e') { //会议大厅
            console.log('会议大厅=====')
            this.getLivePath(item)
        } else if(this.activeTab == 'f') { //产品动态
            this.$router.push({
                name: 'productDetail',
                params:{ id: id},
                query: {
                    productIds: productIds,
                    producType: realmId
                }
            })

            
        }
    },
    // 获取会议路径
    getLivePath(item) {
      let params = {
        meetingId: item.id
      };
      if(item.meetingPattern == 'offline' || 
        (item.meetingPattern == 'online_and_offline'&&item.memberSigninType =='offline_participation')
        ){
            this.$message.error('当前线下会议，请您线下参会');
        }else{
          indexApi.getLivePath(params).then((res)=>{
            if(res && res.code === 1000){
                let data = res && res.data;
                window.open(data.url)
            }
          },(err)=>{
          
          })
        }
    },

    // 回车搜索
    inputSearch(event) {
       
        this.list = [];
        this.dataList = [];
        this.page = '1';
      // debugger
      if (event.keyCode == 13){
        if(!this.searchName){
            return; 
        }
        // this.search();
        if(this.activeTab == 'a') {
          this.getProductSearchList()
        } else if(this.activeTab == 'b') {
          this.getActivityList()
        } else if(this.activeTab == 'c') {
          this.getPerspectiveList();
        } else if(this.activeTab == 'd') {
          this.getDynamicNews()
        } else if(this.activeTab == 'e') {
          this.getPlaybackList()
        }else if(this.activeTab == 'f') {
          this.getDynamicList()
        }
        this.isFocus = false;
      }  
    },

    /**
     * 选择日期
     */
    chooseDate(){
        console.log('date==========',this.date)
        this.handleToggleTab(this.activeTab)
    },

    /**
     * 滑动到底部
     */
    scroll(e){
        const ele = e.srcElement ? e.srcElement : e.target
         // 监听滚动到div底部
        if (ele.scrollTop + ele.offsetHeight == ele.scrollHeight ) { 
            if(this.hasMore){
                console.log('加载更多==============')
                this.handleToggleTab(this.activeTab)
            }
        }
    },
  }
}
</script>

<style scoped lang="scss">
.scroll-container{
    overflow: initial;
}


.search-container {
  background-image: url("~./images/bg.jpg");
  background-size: cover;
  line-height: 1;
}
.list-box-wrap{
    background: #fff;
    border-radius: 0.2rem 0.2rem 0.2rem 0.8rem;
//    height:100%;
    flex:1;
    overflow: hidden;
}
.content-wrapper {
  position: relative;
  margin-top: .6rem;
  background: rgba(255, 255, 255, .75);
  border-radius: .2rem;
  display: flex;
  padding-left: .2rem;
  padding-top: .25rem;
}

.left {
  flex: none;
  width: 1.8rem;
  text-align: center;
  font-size: .18rem;
  color: #333;
  margin-top: 1.52rem;
  padding-bottom:1.78rem;

  li {
    height: .8rem;
    line-height: .8rem;
    cursor: pointer;
    &.active {
      color: #fff;
      background: url("~./images/active.png") center center / 1.8rem .8rem no-repeat;
    }
  }
}

.right {
  flex: 1;
      display: flex;
    flex-direction: column;
  .title {
    font-size: .22rem;
    line-height: 1.32;
    color: #333333;
    padding: 0 .4rem;
    margin-bottom: .25rem;
  }
  
  .date{
       padding: 0 .4rem;
       margin-bottom: .25rem;
  }
  .hyItem{
    .date{
        padding: 0;
        margin-bottom: 0;
    }
  }
  .empty-box{
      text-align: center;
      padding-top:2rem;
  }
  .list-wrapper {
    background: #fff;
    padding: .5rem;
    border-radius: .2rem .2rem .2rem .8rem;
    height: 5.88rem;
    overflow-y: scroll;
    overflow-x: hidden;
    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: .5rem;
      .img {
        width: 2.5rem;
        height: 1.5rem;
        border-radius: .08rem;
        margin-right: .4rem;
      }
      .ct {
        margin-top: .12rem;
        .ct-title {
          font-size: .22rem;
          color: #333333;
          max-width: 10rem;
          margin-bottom: .12rem;
          line-height: 0.28rem;
          text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        }
        .des {
          font-size: .14rem;
          line-height: .24rem;
          margin-bottom: .15rem;
          color: #666666;
          &.bottom0{
            margin-bottom: 0;
          }

        }
        .time{
            font-size: .18rem;
            background: url("../simulation-visit/images/time.png") no-repeat left center/0.16rem 0.16rem;
            padding-left:0.2rem;
            font-weight: normal;
        }

        .tag {
          .t {
                padding: 0 .12rem;
                height: .22rem;
                line-height: .22rem;
                background-color: #21b8ce;
                border-radius: 2px;
                font-size: .14rem;
                color: #fff;

                margin-right: .1rem;
            }
            margin-bottom: 0.2rem;
        }
      }
    }
  }
}

.input-wrapper {
  position: absolute;
  left: -.2rem;
  top: -.3rem;
  background: url("~./images/search-samll.png") center center /100% 100%;
  height: 1.3rem;
  width: 2rem;
  display: flex;
  align-items: center;
  padding: .3rem;
  transition: width .3s;
  &.active {
    background-image: url("~./images/search-bg.png");
    width: 5.02rem;
  }
  .ipt {
    display: block;
    width: 100%;
    border: none;
    outline: none;
    appearance: none;
    line-height: .32rem;
    height: .32rem;
    background: transparent;
    font-size: .22rem;
    color: #fff;
    padding-left: .42rem;
    background: url("~./images/search.png") left center / .32rem .32rem no-repeat;
    &::placeholder {
            color: #fff;
    }
  }
}

.search-list {
    height: 5.54rem;
    overflow-y: scroll;
    overflow-x: hidden;
    padding:0.5rem 0 0 0.5rem;
    li {
      width: 4.06rem;
      position: relative;
      margin-bottom: .4rem;
      float: left;
      margin-right: .41rem;
      &:nth-child(3){
        margin-right: 0;
      }
      &:nth-child(6){
        margin-right: 0;
      }
    }
    .img {
      display: block;
      height: 2.07rem;
      width: 100%;
      border-radius: .08rem .4rem .08rem .08rem;
    }
    .t {
      color: #fff;
      font-size: .16rem;
      max-width: 3rem;
    }
    .bottom{
      position: absolute;
      box-sizing: border-box;
      padding:0 .15rem .15rem;
      height: 1.37rem;
      left: 0;
      bottom: 0;
      background: linear-gradient(to bottom,rgba(0,0,0,0) , rgba(0,0,0,.4));;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      border-radius: 0 0 .08rem .08rem;
    }
    .userInfo{
      font-size: .14rem;
      font-family: Noto Sans CJK SC;
      font-weight: 400;
      color: #FFFFFF;
      margin-top: .1rem;
        .author {
          margin-right: .2rem;
        }
      .user{
          display: inline-block;
          margin-right: .2rem;
          &::before{
            content: '';
            width: .14rem;
            height: .14rem;
            background: url('./images/p.png') no-repeat center/ 100% 100%;
            display: inline-block;
            vertical-align: middle;
            margin-top: -.02rem;
            margin-right: .05rem;
          }
      }
      .date{
        display: inline-block;
        &::before{
            content: '';
            width: .14rem;
            height: .14rem;
            background: url('./images/t.png') no-repeat center/ 100% 100%;
            display: inline-block;
            vertical-align: middle;
            margin-top: -.02rem;
            margin-right: .05rem;
          }
        
      }
    }

  }
   ::v-deep .el-date-editor .el-range-separator{
      width: 0.3rem;
      padding:0;
  }
 ::v-deep  .el-range-editor.el-input__inner{
     width:4rem;
     height: 0.4rem;
     padding:0 10px;
 }
 ::v-deep .el-range-editor .el-range-input{
     font-size: 0.14rem;
 }
 ::v-deep .el-date-editor .el-range-separator,
 ::v-deep .el-date-editor .el-range__icon{
     line-height: 0.38rem;
     font-size: 0.14rem;
 }
 ::v-deep .el-date-editor .el-range__close-icon{
     font-size: 0.14rem;
     width: 0.25rem;
     line-height: 0.4rem;
 }
</style>
